$(function() {

    $("#auth_link, #auth_mob_link, #fav_auth_link, #comm_auth_link").on("click", function() {

        document.body.classList.add('hide');
		$(".auth-popup-block").addClass("show-block");
		$("html,body").animate({scrollTop: 0}, 400);

		setTimeout(function() {

			$(".auth-popup-block").addClass("show-block_effect");

		}, 200);

	});

    $(document).keydown(function(event) {
        if (event.which == 27) {
            closeAuthPopup();
        }
    });

    function closeAuthPopup() {
        document.body.classList.remove('hide');
        $(".auth-popup-block").removeClass("show-block");

        setTimeout(function() {
            $(".auth-popup-block").removeClass("show-block_effect");
        }, 200);
    }

	$(".auth-popup-close-link").on("click", function() {

		setTimeout(function() {

			$(".auth-popup-block").removeClass("show-block");
            document.body.classList.remove('hide');

		}, 400);

	});

    $(".auth-popup-twofactor-close").on("click", function() {

        setTimeout(function() {

            $(".auth-popup-block-twofactor").removeClass("show-block");
            $("body, html").css({"overflow": ""});

        }, 400);

    });

    $("#auth_form_sing_in").on("click", function() {

        $.ajax({

            url: "/engine/ajax/auth_sign_in.php",
            type: "post",

            data: {

                "email": $("#login_name").val(),
                "password": $("#login_password").val(),

            }, beforeSend: function() {

                $(".auth-form-wait").show();

            }, success: function(data) {

                $(".auth-form-wait").hide();
                $("#sign_result").html(data);

                setTimeout(function() {

                    $("#sign_result").html("");

                }, 5000);

            }, error: function() {

                $(".auth-form-wait").hide();

            }

        });

    });

    $("#auth_form_lost_pass").on("click", function() {

        $.ajax({

            url: "/engine/ajax/auth_lost_pass.php",
            type: "post",

            data: {

                "lostname": $("#lost_name").val(),

            }, beforeSend: function() {

                $(".auth-form-wait").show();

            }, success: function(data) {

                $(".auth-form-wait").hide();
                $("#pass_result").html(data);

                setTimeout(function() {

                    $("#pass_result").html("");

                }, 5000);

            }, error: function() {

                $(".auth-form-wait").hide();

            }

        });

    });

	$("#auth_form_sing_up").on("click", function() {

		$.ajax({

			url: "/engine/ajax/auth_sign_up.php",
			type: "post",

 			data: {

				"login": $("#auth_form_input_login").val(),
				"email": $("#auth_form_input_email").val(),
				"password": $("#auth_form_input_password").val(),
                "password2": $("#auth_form_input_password2").val(),
				"auth": $("#auth_form_checkbox").prop("checked"),

			}, beforeSend: function() {

				$(".auth-form-wait").show();

			}, success: function(data) {

				$(".auth-form-wait").hide();
				$("#reg_result").html(data);

				setTimeout(function() {

					$("#reg_result").html("");

				}, 5000);

			}, error: function() {

				$(".auth-form-wait").hide();

			}

		});

	});

});
